import {
	Search,
	Home,
	ArrowLeft,
	Warehouse,
	Package,
	Truck,
} from 'lucide-react'
import { Link, useNavigate } from 'react-router'
import { Button } from '#app/components/ui/button'

export const taglines = [
	'This product is backordered... indefinitely.',
	'Our warehouse elves misplaced this one—oops!',
	'Currently shipping… to a parallel universe.',
	'Lost in transit—somewhere between here and the cloud.',
	'This page is undergoing an extended quality control check.',
	'Looks like our barcode scanner forgot to scan this one.',
	'This product is taking a scenic route through cyberspace.',
	'Out of stock… and out of sight!',
	'This page got rerouted to the wrong distribution center.',
	'Estimated arrival time: Unknown.',
	'This item is still on the factory floor—literally.',
	'Our virtual forklifts are still looking for this page.',
	'Sorry! This inventory bin is empty.',
	'404: Freight Overload Detected.',
	'This page is caught in a supply chain traffic jam.',
	'We shipped this page… but forgot the tracking number.',
	'This item was last seen on a slow-moving cargo ship.',
	'Our system flagged this page for a random security check.',
	'This page took a wrong turn at the fulfillment center.',
	'Whoops! We accidentally put this in the ‘returns’ bin.',
	'This product is still being assembled—virtually.',
	'Page not found—maybe try the loading dock?',
	'Our inventory AI is still crunching the numbers on this one.',
	'This page went on a break and never came back.',
	'We blame the warehouse ghost for this missing page!',
]

export const icons = { Package, Truck, Warehouse }

const defaultIcon = Object.keys(icons)[
	Math.floor(Math.random() * Object.keys(icons).length)
] as keyof typeof icons

const defaultTagline = taglines[
	Math.floor(Math.random() * taglines.length)
] as string

export default ({
	error: {
		data: { icon = defaultIcon, message = defaultTagline },
	},
}: {
	error: { data: { icon: keyof typeof icons; message: string } }
}) => {
	const navigate = useNavigate()
	const Icon = icons[icon]
	return (
		<div className="flex flex-col items-center justify-center bg-background text-foreground">
			<div className="max-w-2xl px-4 text-center">
				<h1 className="mb-4 text-6xl font-bold">404</h1>
				<div className="mb-8 h-20">
					<p className="text-2xl transition-opacity duration-500 ease-in-out">
						{message}
					</p>
				</div>
				<div className="relative mb-8">
					<div className="absolute inset-0 flex animate-pulse items-center justify-center">
						<div className="h-32 w-32 rounded-full bg-primary/20 blur-xl"></div>
					</div>
					<div className="relative flex animate-bounce justify-center motion-reduce:animate-none">
						<Icon className="h-32 w-32 text-primary" />
					</div>
				</div>
				<p className="mb-8 text-lg">
					Don't worry, we'll get your supply chain back on track!
				</p>
				<div className="flex flex-col justify-center space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
					<Link to="/marketplace/search">
						<Button
							variant="default"
							className="flex w-full items-center space-x-2 sm:w-auto"
						>
							<Search className="h-4 w-4" />
							<span>Search Marketplace</span>
						</Button>
					</Link>
					<Link to="/marketplace">
						<Button
							variant="outline"
							className="flex w-full items-center space-x-2 sm:w-auto"
						>
							<Home className="h-4 w-4" />
							<span>Return Home</span>
						</Button>
					</Link>
					<Button
						variant="ghost"
						className="flex w-full items-center space-x-2 sm:w-auto"
						onClick={() => navigate(-1)}
					>
						<ArrowLeft className="h-4 w-4" />
						<span>Go Back</span>
					</Button>
				</div>
			</div>
		</div>
	)
}
